import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AsyncView from 'src/components/AsyncView/AsyncView';
import EventHistory from 'src/components/EventHistory/EventHistory';
import PageWrapper from 'src/components/PageWrapper/PageWrapper';
import Background from 'src/components/Background/Background';

import { isTypename } from 'src/utilities/TSUtilities';
import { useGetTeamProfileForInvitePageQuery } from 'src/generated/graphql';

import ClientBackgrounds from 'HiveClient/components/Backgrounds/Backgrounds';
import { faUsers } from 'HiveClient/components/Icons/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../Team/Team.module.scss';
import { useImage } from 'react-image';
import { DEFAULT_BACKGROUND } from 'src/config/Backgrounds/Backgrounds';
import TeamProfileCard from '../Team/TeamProfileCard';
import TeamRoster from '../Team/TeamRoster';
import { JoinTeam } from './components/JoinTeam';
import { useRouteMatch } from 'react-router-dom';
import { useAuthContext } from '../Authentication/AuthContext';

type TeamInvitePageParamMatch = {
  teamId: string;
  code: string;
};

const TeamInviteLink = () => {
  const { t } = useTranslation('team');

  const [{ userId }] = useAuthContext();

  const {
    params: { code, teamId },
  } = useRouteMatch<TeamInvitePageParamMatch>();

  const getTeamProfileResponse = useGetTeamProfileForInvitePageQuery({
    variables: {
      id: teamId,
    },
  });

  const team = useMemo(() => {
    if (
      getTeamProfileResponse.data?.account &&
      isTypename('Team', getTeamProfileResponse.data.account)
    ) {
      return getTeamProfileResponse.data.account;
    }
  }, [getTeamProfileResponse.data?.account]);

  const isTeamMember = useMemo(
    () => team?.groupMembers.some((member) => member.account.id === userId),
    [team, userId]
  );

  const { src } = useImage({
    useSuspense: false,
    srcList: [
      `${team?.bannerURL}`,
      DEFAULT_BACKGROUND,
      ClientBackgrounds.storeBackground,
    ],
  });

  return (
    <Fragment>
      <Background background={src}>
        <div className={styles.profileBackground} />
      </Background>
      <PageWrapper noBackgroundImage>
        <Container>
          <AsyncView
            loading={getTeamProfileResponse.loading}
            error={
              getTeamProfileResponse.error
                ? {
                    heading: t('loading-team.error-notification.title'),
                    message: t('loading-team.error-notification.body'),
                    icon: <FontAwesomeIcon icon={faUsers} />,
                  }
                : undefined
            }
          >
            <Fragment>
              {team && (
                <Row>
                  <Col lg={3}>
                    <TeamProfileCard noEdit team={team} />
                  </Col>
                  <Col lg={9}>
                    <JoinTeam
                      title={
                        isTeamMember ? (
                          <h2>{t('invite-link.already-member-team')}</h2>
                        ) : (
                          <h2>
                            {t('invite-link.invited-to-team')}
                            <strong>{' ' + team.name}</strong>
                          </h2>
                        )
                      }
                      description={
                        !isTeamMember && (
                          <span className='tw-text-xs'>
                            {t('invite-link.invited-to-team-description')}
                          </span>
                        )
                      }
                      variant='team'
                      isTeamMember={isTeamMember}
                      inviteCode={code}
                    />
                    <TeamRoster noManageRooster team={team} />
                    <EventHistory
                      events={team.eventParticipations}
                      accountId={team.id}
                    />
                  </Col>
                </Row>
              )}
              {!team && !getTeamProfileResponse.loading && (
                <h2 className={'mt-3'}>{t('team-not-found.header')}</h2>
              )}
            </Fragment>
          </AsyncView>
        </Container>
      </PageWrapper>
    </Fragment>
  );
};

export default TeamInviteLink;
