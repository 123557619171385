import { useEffect } from 'react';
import { addTolocalStorage } from '../utilities/Utilities';
import { StorageKey } from '../config/Enums/App';
import { PasswordRedirectQueryParams } from '../config/Enums/QueryParams';
import Routes from '../config/Routes/Routes';
import { useQueryParams } from 'use-query-params';

const useHandleResetPasswordRedirect = (
  handleUserRedirect: (to: string, state?: any) => void
) => {
  const [urlQuery] = useQueryParams(PasswordRedirectQueryParams);

  useEffect(() => {
    const {
      passwordResetToken: passwordResetTokenURLQuery,
      verifiedContact: verifiedEmailURLQuery,
    } = urlQuery;

    const handleResetPasswordRedirect = (token: string, email: string) => {
      addTolocalStorage(StorageKey.VerifiedEmail, email);
      addTolocalStorage(StorageKey.PasswordResetToken, token);
      handleUserRedirect(Routes.resetPassword);
    };

    if (passwordResetTokenURLQuery && verifiedEmailURLQuery) {
      handleResetPasswordRedirect(
        passwordResetTokenURLQuery,
        verifiedEmailURLQuery
      );
    }
  }, [handleUserRedirect, urlQuery]);
};

export default useHandleResetPasswordRedirect;
