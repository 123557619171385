/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import { StoreItemDetailPromo } from '../../../../pages/Store/StoreItem/StoreItemDetailsPromo';
import { Fragment, useMemo, useState } from 'react';
import {
  ProductType,
  StoreCategoryFragment,
  useCategoryCatalogQuery,
  useCategoryProductGroupsQuery,
} from 'src/generated/graphql';
import StoreItemRedemptionDetails from 'HiveClient/components/StoreItem/StoreItemRedemptionDetails';
import { StoreItemData } from '../../../../pages/Store/useStoreReducer';
import { StoreItemDetailDescription } from '../../../../pages/Store/StoreItem/StoreItemDetailDescription';
import useGetMinimalUserQuery from 'src/hooks/useGetMinimalUserQuery';
import { useAuthContext } from 'src/pages/Authentication/AuthContext';
import { isTypename } from 'src/utilities/TSUtilities';
import { StoreItemDetailCurrencyOptions } from '../../../../pages/Store/StoreItem/StoreItemDetailCurrencyOptions';
import { StoreItemDetailOptions } from '../../../../pages/Store/StoreItem/StoreItemDetailOptions';
import {
  useFormattedPriceOptions,
  useMerchantPaymentOptions,
} from '../../../../pages/Store/StoreHelpers';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { useStoreContext } from '../../../../pages/Store/StoreContext';
import { Button } from 'react-bootstrap';
import EnvVariables from '../../../../config/EnvVariables/EnvVariables';
import Routes from '../../../../config/Routes/Routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface StoreItemPrice {
  currency: string;
  price: number;
  tagId: string;
}

export interface StoreItemDetailBodyProps {
  onHide: () => void;
  name: string;
  description?: string;
  category?: string;
  currencyName?: string;
  pointsValue?: number;
  categoryId: string;
  prices?: StoreItemPrice[] | null;
  image?: string;
  productType?: ProductType | null;
  currentItem?: StoreItemData | null;
  disableButton: boolean;
  loading: boolean;
  promoEnabled?: boolean;
  detailDenominationEnabled?: boolean;
  itemOptions: StoreItemData[];
  onConfirm: (fields: { [key: string]: string | number }) => void;
}

enum PageState {
  ItemList = 'item-list',
  DetailsFields = 'details-fields',
}

export const StoreItemDetailBody = (props: StoreItemDetailBodyProps) => {
  const { t } = useTranslation('store');
  const history = useHistory();
  const [pageState, setPageState] = useState<PageState>(PageState.ItemList);

  const allStoreCategoriesRes = useCategoryCatalogQuery({
    variables: {
      partnerId: EnvVariables.storePartnerId ?? '',
    },
  });

  const currentCategory: StoreCategoryFragment | undefined =
    allStoreCategoriesRes.data?.categoryCatalog?.categories
      ?.filter(
        (category): category is StoreCategoryFragment => category !== null
      )
      .filter(
        (category): category is StoreCategoryFragment =>
          category.id === props.categoryId
      )
      .at(0);

  const availableStoreItemsRes = useCategoryProductGroupsQuery({
    variables: {
      partnerId: EnvVariables.storePartnerId ?? '',
      categoryId: props.categoryId,
      limit: 2,
      offset: 0,
    },
    initialFetchPolicy: 'cache-first',
  });

  const productGroups =
    availableStoreItemsRes.data?.categoryProductGroups?.productGroups;

  return (
    <Fragment>
      <Button
        variant='link'
        className='tw-flex tw-gap-2 tw-items-center tw-m-2 tw-max-w-fit'
        onClick={() => {
          if (pageState === PageState.DetailsFields) {
            setPageState(PageState.ItemList);
          } else {
            history.push(
              productGroups && productGroups.length > 1
                ? `${Routes.store}/${currentCategory?.id}/`
                : Routes.store
            );
          }
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        {t('button.back')}
      </Button>
      <div className='tw-Frame25 tw-self-stretch tw-justify-center tw-items-start tw-gap-8 tw-flex tw-flex-wrap'>
        {pageState === PageState.ItemList && (
          <ItemList
            image={props.image}
            name={props.name}
            productType={props.productType}
            description={props.description}
            itemOptions={props.itemOptions}
            promoEnabled={props.promoEnabled}
            onItemOptionClick={() => setPageState(PageState.DetailsFields)}
          />
        )}
        {pageState === PageState.DetailsFields && (
          <DetailsFields
            currencyName={props.currencyName}
            pointsValue={props.pointsValue}
            currentItem={props.currentItem}
            onHide={props.onHide}
            loading={props.loading}
            onConfirm={props.onConfirm}
            productType={props.productType}
            disableButton={props.disableButton}
            onBack={() => setPageState(PageState.ItemList)}
          />
        )}
      </div>
    </Fragment>
  );
};

const ItemList = (props: {
  image?: string;
  name: string;
  productType?: ProductType | null;
  description?: string;
  itemOptions: StoreItemData[];
  promoEnabled?: boolean;
  onItemOptionClick: () => void;
}) => (
  <div
    data-semantic='leftCol'
    className='tw-Frame24 tw-grow tw-basis-7/12 lg:tw-basis-6/12 tw-justify-center tw-gap-4 tw-flex tw-flex-col'
  >
    <div className='tw-Banner tw-h-48 tw-bg-white dark:tw-bg-zinc-900 tw-rounded-3xl tw-border tw-border-zinc-700 tw-overflow-clip'>
      <img
        className='tw-BackgroundImage tw-h-full tw-w-full tw-object-cover tw-object-center' // center this vertically in the containing div
        // src={'https://place-puppy.com/800x450'}
        src={props.image}
      />
    </div>
    <div className='tw-Heading tw-flex-col tw-justify-start tw-items-start tw-gap-0 tw-inline-flex'>
      <div className='tw-MobileLegendsBangBang tw-text-black dark:tw-text-zinc-100 tw-text-2xl tw-font-bold tw-leading-7'>
        {props.name}
      </div>
      <div className='tw-DirectTopUp tw-text-[#938F99] tw-text-lg tw-font-normal tw-mt-2 tw-leading-snug'>
        {props.productType}
      </div>
    </div>
    <div className=''>
      {props.description && (
        <StoreItemDetailDescription description={props.description} />
      )}
    </div>

    <StoreItemDetailOptions
      onOptionClick={() => props.onItemOptionClick()}
      itemOptions={props.itemOptions}
    />

    {props.promoEnabled && <StoreItemDetailPromo />}
  </div>
);

const DetailsFields = (props: {
  currencyName?: string;
  pointsValue?: number;
  currentItem?: StoreItemData | null;
  onHide: () => void;
  loading: boolean;
  onConfirm: (fields: { [key: string]: string | number }) => void;
  productType?: ProductType | null;
  disableButton: boolean;
  onBack: () => void;
}) => {
  const [authContextState] = useAuthContext();
  const getUserQuery = useGetMinimalUserQuery();
  const { dispatch } = useStoreContext();

  const priceOptions = useFormattedPriceOptions(
    props.currencyName,
    props.pointsValue,
    props.currentItem
  );

  const defaultPriceOption = useMemo(
    () =>
      priceOptions[0].name === 'Points' ? priceOptions[1] : priceOptions[0],
    [priceOptions]
  );

  useMerchantPaymentOptions({
    price: defaultPriceOption.price,
    currency: defaultPriceOption.currency,
    skip: !GlobalClientConfig.currency?.hideCurrencySelectOnStore,
    onCompleted: (data) => {
      const result = data.getPaymentMethodsAvailableForMerchant;
      if (
        GlobalClientConfig.currency?.hideCurrencySelectOnStore &&
        result.length
      ) {
        console.log(
          'SELECT_ITEM_PAYMENT_OPTION for priceOptions[0] and paymentOptionsForDefaultPriceOptions[0]',
          priceOptions[0],
          result[0]
        );

        dispatch({
          type: 'SELECT_ITEM_PAYMENT_OPTION',
          payload: {
            option: defaultPriceOption,
            method: result[0] as any,
          },
        });
      }
    },
  });

  function getFieldsWithEmailAdded(props: {
    currentItem?: StoreItemData | null;
  }): import('./StoreItemRedemptionDetails').IField[] {
    // TODO: refactor this into a more generic hook for determining user defaults
    // Read the PassedAuthorization cookie
    const passedAuthorization = document.cookie
      .split('; ')
      .find((row) => row.startsWith('PassedAuthorization='))
      ?.split('=')[1];

    let defaultEmail = '';

    if (passedAuthorization) {
      try {
        const decodedToken = JSON.parse(
          atob(passedAuthorization.split('.')[1])
        );
        defaultEmail = decodedToken.encryptedMsisdn || '';
        defaultEmail = defaultEmail + '@swarmio.orange';
      } catch (error) {
        console.error('Error decoding JWT:', error);
      }
    } else {
      defaultEmail =
        authContextState.userId &&
        getUserQuery.data?.user?.email &&
        isTypename('StringAttribute', getUserQuery.data.user.email)
          ? getUserQuery.data.user.email.value
          : '';
    }
    const adjustedFields = (props.currentItem?.fields ?? []).concat({
      name: 'email',
      type: 'email',
      data: [{ name: 'email', value: defaultEmail }],
    });
    return adjustedFields;
  }

  return (
    <div
      data-semantic='rightCol'
      className='tw-AccountInfo tw-basis-full lg:tw-basis-4/12 tw-flex-col tw-justify-center tw-items-center tw-gap-8 tw-flex'
    >
      {!GlobalClientConfig.currency?.hideCurrencySelectOnStore && (
        <StoreItemDetailCurrencyOptions
          currencyName={props.currencyName}
          currentItem={props.currentItem}
          priceOptions={priceOptions}
        />
      )}

      <StoreItemRedemptionDetails
        onConfirm={props.onConfirm}
        onHide={props.onHide}
        loading={props.loading}
        productType={props.productType}
        disableButton={props.disableButton}
        fields={getFieldsWithEmailAdded(props)}
        price={props.pointsValue ?? 0}
        currencyName={props.currencyName ?? 'beans'}
        onBack={props.onBack}
      />
    </div>
  );
};
