import { Fragment, Suspense, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { StorageKey } from './config/Enums/App';
import Dashboard from './components/Dashboard/Dashboard';
import ClientHelmet from './components/ClientHelmet/ClientHelmet';

import Routes from './config/Routes/Routes';
import useWatchGetUserForPermissions from './hooks/useWatchGetUserForPermissions';
import Router from './Router';
import useScrollToTop from './hooks/useScrollToTop';
import useHandleErrorQueryParams from './hooks/useHandleErrorQueryParams';
import useHandleParamsFromSocialRedirect from './hooks/useHandleParamsFromSocialRedirect';
import useHandlePaymentRedirect from './hooks/useHandlePaymentRedirect';
import useHandleResetPasswordRedirect from './hooks/useHandleResetPasswordRedirect';
import useHandleAccountVerificationRedirect from './hooks/useHandleAccountVerificationRedirect';
import useGetTwitchAuthToken from './hooks/useGetTwitchAuthToken';
import useHandleCompleteAccountRedirect from './hooks/useHandleCompleteAccountRedirect';
import useIntercomBoot from './hooks/useIntercomBoot';
import GlobalGradient from './components/GlobalGradient/GlobalGradient';
import useGTMDataLayer from './hooks/useGTMDataLayer';
import useSubscribeToNewTeamInvitations from './hooks/useSubscribeToNewTeamInvitation';
import useCreateWalletIfNone from './hooks/useCreateWalletIfNone';
import useHandleSwarmioPayQueryParams from './hooks/useHandleSwarmioPayQueryParams';
import { clearRedirectDetails } from './services/RedirectPathService';
import Spinner from './components/Spinner/Spinner';
import useLogger from './hooks/useLogger';
import Footer from 'HiveClient/components/Footer/Footer';

export type State = {
  email: string | null;
  displayName: string | null;
  msisdn: string | null;
  msisdnVerified: boolean;
  preventNavigation: boolean;
};

const revokeToken = () => {
  localStorage.removeItem(StorageKey.AuthToken);
};

/**
 * Refreshing the page ensures that the apollo cache is fresh, and all cached queries are cleared.
 * Apollo Client has some strange behaviour around cached query variables
 * even after calling clearStore().
 */
export const handleSignOut = (redirectPath?: string) => {
  revokeToken();
  clearRedirectDetails();
  window.location.href = redirectPath ? redirectPath : Routes.home;
};

const App = () => {
  const history = useHistory();

  const [appState, setAppState] = useState<State>({
    email: null,
    displayName: null,
    msisdn: null,
    msisdnVerified: false,
    preventNavigation: false,
  });

  const handleUserRedirect = useCallback(
    (to: string, state?: any) => {
      history.push(to, state);
    },
    [history]
  );

  useScrollToTop();
  useHandleErrorQueryParams();
  useHandleParamsFromSocialRedirect(handleUserRedirect);
  useHandlePaymentRedirect(handleUserRedirect);
  useHandleResetPasswordRedirect(handleUserRedirect);
  useHandleAccountVerificationRedirect(handleUserRedirect, setAppState);
  useGetTwitchAuthToken();
  useHandleCompleteAccountRedirect(handleUserRedirect, setAppState);
  useWatchGetUserForPermissions();
  useIntercomBoot(appState);
  useGTMDataLayer();
  useSubscribeToNewTeamInvitations();
  useCreateWalletIfNone();
  useHandleSwarmioPayQueryParams();
  useLogger();

  return (
    <Fragment>
      <ClientHelmet />
      <GlobalGradient />
      <Dashboard
        handleSignOut={handleSignOut}
        preventNavigation={appState.preventNavigation}
      >
        <Suspense
          fallback={
            <div
              className={
                'd-flex justify-content-center padding-top-menu-height'
              }
            >
              <Spinner />
            </div>
          }
        >
          <Router appState={appState} setAppState={setAppState} />
          <Footer preventNavigation={appState.preventNavigation} />
        </Suspense>
      </Dashboard>
    </Fragment>
  );
};

export default App;
