export enum FeaturesEnum {
  Wallet = 'store:wallet',
  Streams = 'streams',
  Store = 'store',
  AllowAddingCurrency = 'store:addSwarmioCurrency',
  Arcade = 'arcade',
  Keycloak = 'keycloak',
  OnboardingEvent = 'store:onboardingEvent',
  StoreNavigation = 'store:navigation',
  Events = 'events',
  StreamsOnHomePage = 'streams:homePage',
  Subscriptions = 'subscriptions',
  SubscriptionsUpgrade = 'subscriptions:upgrade',
  SubscriptionsUnsubscribe = 'subscriptions:unsubscribe',
  CheckinIndicators = 'events:checkinIndicators',
  FeaturedStoreHomepage = 'store:featuredHomepage',
  RemoteLocalizations = 'localizations:remote',
  UpcomingMatchTimes = 'events:upcomingMatchTimes',
  HeaderIcons = 'header:icons',
  StoreBrowseByGamesToBottom = 'store:sections:browseByGames:order:bottom',
  StoreBanners = 'store:banners',
  StoreProfile = 'store:profile',
  FooterIcons = 'footer:icons',
}
