import { ClientRoute } from 'HiveClient/config/Routes/Routes';
import TermsAndConditions from 'HiveClient/pages/Terms';

// these CANNOT be used outside of the client pack they belong to.
export const ClientRoutePaths = {
  terms: '/terms-conditions',
};

export const ClientRoutes: ClientRoute[] = [
  {
    component: TermsAndConditions,
    path: ClientRoutePaths.terms,
  },
];

export default ClientRoutes;
