/**
 * This is meant for keeping track of what page a user was previously on before being sent offsite for whatever reason.
 *
 * We set both localStorage and sessionStorage for different reasons. Consumers of this service may only be interested
 * in the previously visited route of the current browsing session vs previously visited route of any session for that device.
 *
 * Concrete examples:
 *
 * User uses social auth to login - we want to read from sessionStorage when they're returned from the social site so we
 * can redirect them to the page they were on before navigating to the sign in page. We don't use localStorage here because
 * it might be from an older session and be confusing if they navigate directly to /sign-in and then get redirected to
 * somewhere unrelated to their current browsing session.
 *
 * On the other hand, if they're registering a new account, we want to read from localStorage after they verify their email
 * as it will be a new browsing session, and we want to give them the opportunity to return to whatever page they were on before
 * registering.
 */
import Routes from '../config/Routes/Routes';
import {
  getFromlocalStorage,
  getFromSessionStorage,
} from '../utilities/Utilities';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { IRouteTracking } from '../hooks/useTrackPreviousRoute';

export const RedirectPathStorageKey = 'redirectPath';

/**
 * Routes we don't want to overwrite the tracked redirect path with.
 */
export const routesToIgnore = [
  Routes.verifyEmail,
  Routes.accountVerified,
  Routes.signIn,
  Routes.createAccount,
  Routes.completeAccount,
  Routes.resetPassword,
  Routes.forgotPassword,
  Routes.msisdn,
  Routes.verifySMS,
  Routes.verifyEmail,
  Routes.settings,
  GlobalClientConfig.accountSetupRoute,
];

export const setRedirectDetails = (route: IRouteTracking) => {
  /**
   * If there is no redirectPath in sessionStorage, it means whatever page they're on is the first page they've visited
   * this session. If it's the homepage, we don't want to set it because all of the external redirects into our app
   * happen at the homepage with query parameters (social auth, verification from email, etc). We need to maintain
   * the previously set redirectPath so we can actually do something with it.
   */
  if (getRedirectDetails() || route.path !== Routes.home) {
    localStorage.setItem(RedirectPathStorageKey, JSON.stringify(route));
    sessionStorage.setItem(RedirectPathStorageKey, JSON.stringify(route));
  }
};

export const getRedirectDetails = (local: boolean = false) =>
  local
    ? getFromlocalStorage<IRouteTracking>(RedirectPathStorageKey)
    : getFromSessionStorage<IRouteTracking>(RedirectPathStorageKey);

export const clearRedirectDetails = () => {
  sessionStorage.removeItem(RedirectPathStorageKey);
  localStorage.removeItem(RedirectPathStorageKey);
};
