import { AUTHENTICATION_SERVICE_URL } from '../../config/Endpoints/Endpoints';

export interface Authentication {
  authenticate: (params: Credentials) => Promise<TokenResponse | undefined>;
  verifyEmail: (
    params: VerificationRequest
  ) => Promise<TokenResponse | undefined>;
  refreshToken: (params: {
    accountId: string;
    refreshToken: string;
  }) => Promise<TokenResponse | undefined>;
  authenticateAsOther: (params: {
    otherAccountId: string;
    accessToken: string;
  }) => Promise<JoinOtherTokenResponse>;
  authenticateWithMaxIt: (params: {
    token: string;
  }) => Promise<TokenResponse | undefined>;
}

export interface RefreshToken {
  token: string;
  expiry: string;
  isSuperUser: boolean;
}

export interface TokenResponse {
  accessToken: string;
  refreshToken: RefreshToken;
  accountId: string;
}

export interface JoinOtherTokenResponse extends TokenResponse {
  refreshToken: {
    accountId: string;
    effectiveId: string;
    effectiveType: string;
    roles: Array<any>;
  } & RefreshToken;
}

export interface Credentials {
  identifier: string;
  password: string;
}

export interface VerificationRequest {
  verificationToken: string;
  verifiedContact: string;

  utmSource?: string;
  utmCampaign?: string;
}

export function verifyEmail(
  params: VerificationRequest
): Promise<TokenResponse | undefined> {
  const body: string = JSON.stringify(params);

  return fetch(`${AUTHENTICATION_SERVICE_URL}/verifyContact`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .catch((err) => {
      console.error('Error in authentication service: ', err);
      throw new Error('Authentication service error');
    });
}

export function authenticate(
  params: Credentials
): Promise<TokenResponse | undefined> {
  const body: string = JSON.stringify(params);

  return fetch(`${AUTHENTICATION_SERVICE_URL}/authenticate/password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
}

export function authenticateWithMaxIt(params: {
  token: string;
}): Promise<TokenResponse | undefined> {
  const body: string = JSON.stringify(params);

  return fetch(`${AUTHENTICATION_SERVICE_URL}/authenticateWithMaxIt`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
}

/**
 * Fetches a new accessToken.
 *
 * If the user's accessToken is expired but their refresh token is still good,
 * the refresh endpoint will return a new accessToken and refreshToken.
 *
 * Currently the backend will invalidate any previous access/refresh tokens upon
 * requesting new ones. Because of this, if multiple requests to refresh are triggered
 * concurrently, then a race condition is possible where the last refresh call to return
 * to the client is actually not the most recent call, meaning the returned tokens are invalid.
 *
 * Because of this, refreshToken() keeps track of its inflight request. If called while
 * a request is already inflight, it returns that unresolved Promise. Otherwise, it generates
 * a new request.
 *
 * This allows especially for apollo-link-error to only make a single request for a new token
 * regardless of how many graphql calls failed because of an expired token, and then
 * use the fresh accessToken to retry all the failed graphql calls.
 *
 * @param params
 */
export function refreshToken(
  this: { inFlightRequest: Promise<TokenResponse | undefined> | null },
  params: {
    accountId: string;
    refreshToken: string;
  }
): Promise<TokenResponse | undefined> {
  const body: string = JSON.stringify(params);

  if (!this.inFlightRequest) {
    this.inFlightRequest = fetch(
      `${AUTHENTICATION_SERVICE_URL}/authenticate/refresh`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .finally(() => {
        this.inFlightRequest = null;
      });
  }

  return this.inFlightRequest;
}

export function authenticateAsOther(params: {
  otherAccountId: string;
  accessToken: string;
}): Promise<JoinOtherTokenResponse> {
  return fetch(
    `${AUTHENTICATION_SERVICE_URL}/authenticate/exchange/${params.otherAccountId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accessToken: params.accessToken }),
    }
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return Promise.reject(res);
    }
  });
}

const AuthenticationService: Authentication = {
  authenticate,
  verifyEmail,
  refreshToken,
  authenticateAsOther,
  authenticateWithMaxIt,
};

export default AuthenticationService;
