import supportedLanguages from './supported-languages';
import { ClientLanguage } from '../../../../services/LanguageService';
import {
  AuthenticationFlowTypes,
  FeaturedEventsCarouselType,
} from '../../../../config/Enums/App';
import { CountryCode } from 'libphonenumber-js';
import Routes from '../../../../config/Routes/Routes';
import { Region } from '../../../../generated/graphql';
import { MsisdnRequiredness } from '../../../../config/Enums/MsisdnRequiredness';

export interface IGlobalClientConfig {
  siteTitle: string;
  clientName: string;
  copyrightName: string;
  partnerCopyrightName?: string;
  theme: 'tw-light' | 'tw-dark';
  clientOrganizationSiteUrl: string;
  marketingSiteUrl?: string;
  homeRedirectTo?: string;
  socialLinks: {
    facebook?: string;
    twitter?: string;
    instagram?: string;
    youtube?: string;
    email?: string;
    discord?: string;
  };
  nav: {
    orientation: 'vertical' | 'horizontal' | 'hidden';
  };
  internationalization: {
    defaultLng: string;
    supportedLngs: ClientLanguage[];
  };
  msisdn: {
    format?: CountryCode;
    requiredness: MsisdnRequiredness;
  };
  allowTokenLogin: boolean;
  showStreamsOnHomePage: boolean;
  numberOfEventsToShowOnHomePage: number;
  numberOfServersToShowOnHomePage: number;
  authenticationFlowType: AuthenticationFlowTypes;
  featuredEventsCarouselType: FeaturedEventsCarouselType;
  accountSetupRoute: string;
  streams: {
    headerType: 'carousel' | 'standalone';
  };
  navigation: {
    eventCategories: boolean;
    hideGameServers: boolean;
    discordURL?: string;
  };
  currency?: {
    showPlusTaxLabel?: boolean;
    showOnlyPoints?: boolean;
    hideCurrencySelectOnStore?: boolean;
  };
  onboardingEventDetails: {
    region: Region;
  };
  eventDiscordLink?: string;
  subscriptions: {
    allowUpgrades: boolean; // not implemented for SLT
    allowUnsubscribe: boolean;
  };
  colors: {
    darkGrey: string;
    lightGrey: string;
    black: string;
    white: string;
    primary: string;
    secondary: string;
    danger: string;
    success: string;
    warning: string;
    dark: string;
    light: string;
    gold: string;
    silver: string;
    bronze: string;
    googleRed: string;
    facebookBlue: string;
    discordBlue: string;
    twitchPurple: string;
    twitterBlue: string;
    youtubeRed: string;
    snapchatYellow: string;
    instagramPink: string;
    tiktokBlue: string;
  };
  showUpcomingMatchTimes: boolean;
  allowSocialSignIn: boolean;
  countryCode: number;
  storeBanner: (storePartnerId: string | undefined) => string;
}

const GlobalClientConfig: IGlobalClientConfig = {
  siteTitle: 'Swarmio Hive',
  clientName: 'Swarmio',
  copyrightName: 'Swarmio Media',
  clientOrganizationSiteUrl: 'https://swarmio.media',
  accountSetupRoute: Routes.connectGameAccounts,
  theme: 'tw-dark',
  allowTokenLogin: false,
  socialLinks: {
    facebook: 'https://www.facebook.com/swarmio.gg/',
    twitter: 'https://www.twitter.com/SwarmioGG/',
    youtube: 'https://www.youtube.com/swarmiotv/',
    email: 'mailto:contact@swarmio.media',
  },
  nav: {
    orientation: 'horizontal',
  },
  internationalization: {
    defaultLng: 'en',
    supportedLngs: supportedLanguages,
  },
  msisdn: {
    format: 'US',
    requiredness: MsisdnRequiredness.OPTIONAL,
  },
  showStreamsOnHomePage: true,
  numberOfEventsToShowOnHomePage: 3,
  numberOfServersToShowOnHomePage: 3,
  authenticationFlowType: AuthenticationFlowTypes.StandAlone,
  featuredEventsCarouselType: FeaturedEventsCarouselType.FullScreen,
  streams: {
    headerType: 'standalone',
  },
  navigation: {
    eventCategories: false,
    hideGameServers: true,
  },
  onboardingEventDetails: {
    region: Region.Na,
  },
  colors: {
    darkGrey: '#6a6a9e',
    lightGrey: '#c2c6d5',
    black: '#0e0e1c',
    white: '#f4f9ff',
    primary: '#ff9050',
    secondary: '#6a6a9e',
    danger: '#ff4060',
    success: '#80ff80',
    warning: '#ffdd00',
    dark: '#19192b',
    light: '#ff9050',
    bronze: '#a77044',
    silver: '#a7a7ad',
    gold: '#ffcb50',
    discordBlue: '#7289da',
    facebookBlue: '#3b5998',
    googleRed: '#d34836',
    twitterBlue: '#1da1f2',
    youtubeRed: '#ff0000',
    instagramPink: '#e1306c',
    tiktokBlue: '#69c9d0',
    snapchatYellow: '#fffc00',
    twitchPurple: '#6441a5',
  },
  // Just something so that the banner appears when working on the default theme.
  eventDiscordLink: 'https://swarmio.media/',
  subscriptions: {
    allowUpgrades: true,
    allowUnsubscribe: true,
  },
  showUpcomingMatchTimes: true,
  allowSocialSignIn: true,
  countryCode: 1,
  storeBanner: (storePartnertId: string | undefined) =>
    `https://store-assets.arenaesports.ae/store-banners/headers/${storePartnertId}/header-image.png`,
};

export default GlobalClientConfig;
