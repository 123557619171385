import { ApolloClient, ApolloQueryResult } from '@apollo/client/core';
import {
  FeatureFlagFragment,
  FeatureFlagState,
  GetApplicationConfigByIdDocument,
  GetApplicationConfigByIdQuery,
} from '../../generated/graphql';
import { FeatureFlagsConfig } from '../../config/FeatureFlags/FeatureFlagsContext';
import EnvVariables from '../../config/EnvVariables/EnvVariables';
import FeatureFlags from 'HiveClient/config/FeatureFlags/FeatureFlags';
import { FeaturesEnum } from '../../config/FeatureFlags/FeaturesEnum';

const mapFeatureFlagsToFeatureFlagsContext = (
  featureFlags: FeatureFlagFragment[]
): FeatureFlagsConfig => {
  const existingFeatureFlags = featureFlags.filter((flag) => {
    const featureFlagKeyTyped = flag.feature.name as keyof typeof FeaturesEnum;
    const maybeFeatureFlag = FeaturesEnum[featureFlagKeyTyped] as
      | string
      | undefined;

    if (maybeFeatureFlag === undefined) {
      console.log(
        `The feature flag [${flag.feature.name}] cannot be found in the config map!`
      );
      return false;
    }

    return maybeFeatureFlag;
  });

  return existingFeatureFlags.reduce<FeatureFlagsConfig>((acc, flag) => {
    const featureFlagKeyTyped = flag.feature.name as FeaturesEnum;
    acc[featureFlagKeyTyped] = flag.state === FeatureFlagState.Enabled;
    return acc;
  }, FeatureFlags);
};
const init = (
  client: ApolloClient<object>
): Promise<FeatureFlagsConfig | void> =>
  client
    .query({
      query: GetApplicationConfigByIdDocument,
      variables: {
        applicationConfigId: EnvVariables.applicationConfigId ?? '',
      },
    })
    .then((response: ApolloQueryResult<GetApplicationConfigByIdQuery>) => {
      if (response.data.applicationConfig === null) {
        console.log('The client config cannot not be found!');
        return Promise.resolve(FeatureFlags);
      }

      const featureFlagConfig = mapFeatureFlagsToFeatureFlagsContext(
        response.data.applicationConfig.featureFlags
      );
      return Promise.resolve(featureFlagConfig);
    })
    .catch((error) => {
      console.log('Error fetching feature flags', error);
      // if for some reason there is an error such as a client config id, and we get a 400 back
      // we will just use the default feature flags which is basically everything disabled, but at least the app will load
      return Promise.resolve(FeatureFlags);
    });

const FeatureFlagConfigService = {
  init,
};

export default FeatureFlagConfigService;
