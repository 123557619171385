import { StringParam, ArrayParam } from 'use-query-params';

export enum SocialAuthRedirectOptions {
  USER_EXISTS_ALREADY_WITH_OTHER_LOGIN = '0',
  CANNOT_AUTHENTICATE_WITH_PROVIDER = '1',
  AUTH_FAILED = '2',
  UNKNOWN_ERROR = '3',
  INVALID_TOKEN = '4',
  // The only time I've seen this is when trying to register with twitch,
  // and the twitch account's email hadn't been verified yet.
  EMAIL_NOT_PROVIDED = '6',
  ALREADY_ASSOCIATED = '8',
}

export enum CompleteAccountStageOptions {
  VERIFY_EMAIL = 'verify-email',
}

export enum HomeErrorOptions {
  SESSION_EXPIRED = 'session-expired',
  NETWORK_ERROR = 'network-error',
}

export const PaymentRedirectQueryParams = {
  paymentStatus: StringParam,
};

export const PasswordRedirectQueryParams = {
  passwordResetToken: StringParam,
  verifiedContact: StringParam,
};

export const AccountVerificationRedirectQueryParams = {
  verificationToken: StringParam,
  verifiedContact: StringParam,
  verificationType: StringParam,
  utm_source: StringParam,
  utm_campaign: StringParam,
};

export const RegistrationUTMQueryParams = {
  utm_source: StringParam,
  utm_campaign: StringParam,
};

export const SocialAuthRedirectQueryParams = {
  accountId: StringParam,
  refreshToken: StringParam,
  token: StringParam,
  socialAuthError: StringParam,
  steamAccountLinkError: StringParam,
  riotAccountLinkError: StringParam,
};

export const EventsQueryParams = {
  date: StringParam,
  category: StringParam,
  games: ArrayParam,
};

export const EventQueryParams = {
  show: StringParam,
  match: StringParam,
};

export const HomeErrorQueryParams = {
  error: StringParam,
};

export const CompleteAccountQueryParams = {
  stage: StringParam,
};

export const SwarmioPayQueryParams = {
  paymentId: StringParam,
  orderId: StringParam,
};

export const StoreQueryParams = {
  itemId: StringParam,
};

export const ConnectGameAccountQueryParams = {
  gameAccountTypeIds: ArrayParam,
  gameId: StringParam,
  eventId: StringParam,
};
