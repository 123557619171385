const Routes = {
  home: '/',
  signIn: '/sign-in',
  signInWithToken: '/sign-in/token/:token',
  createAccount: '/create-account',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  verifyEmail: '/verify-email',
  accountVerified: '/account-verified',
  completeAccount: '/complete-account',
  about: '/about',
  partners: '/partners',
  termsOfUse: '/terms-of-use',
  privacyPolicy: '/privacy-policy',
  wallet: '/my-points',
  myProfile: '/profile',
  profile: '/profile/:idOrPublicURL',
  settings: '/settings',
  connectGameAccounts: '/connect-game-accounts',
  connectGameAccount: '/connect-game-account',
  support: '/support',
  language: '/language',
  faq: '/frequently-asked-questions',
  verificationStepsPubg: '/verification-steps/pubg',
  viewTeam: '/team/:idOrPublicURL',
  manageTeam: '/team/:id/manage',
  teamInviteLink: '/team/:teamId/invite/:code',
  eventTeamInviteLink: '/event/:eventId/team/:teamId/invite/:code',
  createTeam: '/team/create',
  myTeams: '/my-teams',
  serverList: '/server-list',
  watchStreams: '/watch',
  watchStream: '/watch/:id',
  events: '/events',
  tournaments: '/tournaments',
  challenges: '/challenges',
  event: '/event/:id',
  spooky: '/event/:id/spooky',
  eventOverview: '/event/:id/overview',
  eventParticipantsSubRoute: '/participants',
  eventParticipants: `/event/:id/participants`,
  eventDashboardSubRoute: '/dashboard',
  eventDashboard: `/event/:id/dashboard`,
  eventStageCompetition:
    '/event/:id/stages/:stageId/competitions/:competitionId/:competitionType',
  eventStageCompetitionMatch:
    '/event/:id/stages/:stageId/competitions/:competitionId/:competitionType/match/:matchId',
  store: '/store',
  storeCategory: '/store/:categoryId',
  storeCategoryList: '/store/categories',
  storeCategoryItem: '/store/:categoryId/:itemId',
  storeItemSearchResults: '/store/search',
  myOrders: '/my-orders',
  orderDetail: '/my-orders/:purchaseTransactionId',
  arcade: '/arcade',
  subscribe: '/subscribe',
  subscriptions: '/subscriptions',
  onboardingEvent: '/onboarding-event',
  series: '/series/:name',
  msisdn: '/add-msisdn',
  verifySMS: '/verify-sms',
};

export default Routes;
