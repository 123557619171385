import { Dispatch, SetStateAction, useEffect } from 'react';
import { addTolocalStorage } from '../utilities/Utilities';
import { StorageKey } from '../config/Enums/App';
import { AccountVerificationRedirectQueryParams } from '../config/Enums/QueryParams';
import Routes from '../config/Routes/Routes';
import { State } from '../App';
import { useQueryParams } from 'use-query-params';

const useHandleAccountVerificationRedirect = (
  handleUserRedirect: (to: string, state?: any) => void,
  setAppState: Dispatch<SetStateAction<State>>
) => {
  const [urlQuery] = useQueryParams(AccountVerificationRedirectQueryParams);

  useEffect(() => {
    const {
      verificationToken: verificationTokenURLQuery,
      verifiedContact: verifiedEmailURLQuery,
      verificationType: verificationTypeQuery,
      utm_source: utmSourceURLQuery,
      utm_campaign: utmCampaignURLQuery,
    } = urlQuery;

    const handleAccountVerificationRedirect = (
      token: string,
      email: string,
      type: string,
      utmSource?: string,
      utmCampaign?: string
    ) => {
      addTolocalStorage(StorageKey.VerifiedEmail, email);
      addTolocalStorage(StorageKey.VerificationToken, token);
      addTolocalStorage(StorageKey.VerificationType, type);

      if (utmSource) {
        addTolocalStorage(StorageKey.UtmSource, utmSource);
      }

      if (utmCampaign) {
        addTolocalStorage(StorageKey.UtmCampaign, utmCampaign);
      }

      handleUserRedirect(Routes.accountVerified);
      setAppState((prevState) => ({
        ...prevState,
        preventNavigation: true,
      }));
    };

    if (verificationTokenURLQuery && verifiedEmailURLQuery) {
      handleAccountVerificationRedirect(
        verificationTokenURLQuery,
        verifiedEmailURLQuery,
        verificationTypeQuery || 'email',
        utmSourceURLQuery ?? undefined,
        utmCampaignURLQuery ?? undefined
      );
    }
  }, [handleUserRedirect, setAppState, urlQuery]);
};

export default useHandleAccountVerificationRedirect;
