import { useForm } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import { useTranslation } from 'react-i18next';
import { FloatingLabel, Form, FormGroup } from 'react-bootstrap';
import Button from '../../../../components/Button/Button';
import FormControl from 'react-bootstrap/FormControl';
import { ProductType } from 'src/generated/graphql';
import { camelCaseToNormal } from 'src/utilities/Utilities';

import styles from './StoreItem.module.scss';

const getFieldName = (
  fieldName: string,
  localization: (value: string) => string,
  label?: string
) => {
  if (label) {
    return label;
  }

  const statements = {
    userId: localization('item-redemption-form.field.possible-names.userid'),
    accountId: localization(
      'item-redemption-form.field.possible-names.accountid'
    ),
    playerid: localization(
      'item-redemption-form.field.possible-names.playerid'
    ),
    zoneid: localization('item-redemption-form.field.possible-names.zoneid'),
    region: localization('item-redemption-form.field.possible-names.region'),
    server: localization('item-redemption-form.field.possible-names.server'),
    email: localization('email'),
  };

  return (
    statements[fieldName as keyof typeof statements] ||
    camelCaseToNormal(fieldName)
  );
};

type FieldType = 'string' | 'number' | 'select' | 'email';

export interface IField {
  name: string;
  label?: string;
  validation_regex?: string | RegExp;
  sort_order?: number;
  type: FieldType;
  options?: {
    label: string;
    value: string;
  }[];
  data?: { name: string; value: string }[];
}

interface IProps {
  currencyName: string;
  price: number;
  fields?: IField[];
  onHide: () => void;
  onConfirm: (fields: { [key: string]: string | number }) => void;
  loading: boolean;
  disableButton: boolean;
  productType?: ProductType | null;
  onBack: () => void;
}

const StoreItemRedemptionDetails = (props: IProps) => {
  const { t } = useTranslation('store');

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm();

  const renderInputByType = (field: IField) => {
    switch (field.type) {
      case 'select':
        return (
          <FormGroup>
            <FloatingLabel
              label={getFieldName(field.name, t, field.label)}
              className={`${styles.textBlack}`}
            >
              <Form.Select
                key={`${field.name}`}
                className={'mb-3'}
                {...register(`${field.name}`, {
                  pattern: {
                    value: new RegExp(field.validation_regex ?? ''),
                    message: `Please enter a valid ${getFieldName(
                      field.name,
                      t,
                      field.label
                    )}`,
                  },
                  required: t<string>('item-redemption-form.field.isRequired', {
                    fieldName: getFieldName(field.name, t, field.label),
                  }),
                })}
                defaultValue={field.options && field.options[0].value}
              >
                {field.options?.map((options) => (
                  <option value={options.value}>{options.label}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <FormControl.Feedback type={'invalid'}>
              <span>{errors[field.name] && errors[field.name].message}</span>
            </FormControl.Feedback>
          </FormGroup>
        );
      case 'number':
        return (
          <FormGroup>
            <Input
              className={'mb-3'}
              key={`${field.name}`}
              {...register(`${field.name}`, {
                pattern: {
                  value: new RegExp(field.validation_regex ?? ''),
                  message: `Please enter a valid ${getFieldName(
                    field.name,
                    t,
                    field.label
                  )}`,
                },
                valueAsNumber: true,
                required: t<string>('item-redemption-form.field.isRequired', {
                  fieldName: getFieldName(field.name, t, field.label),
                }),
              })}
              label={
                <span className={`${styles.textBlack}`}>
                  {getFieldName(field.name, t, field.label)}
                </span>
              }
              isInvalid={Boolean(errors[field.name])}
              feedback={
                <FormControl.Feedback type={'invalid'}>
                  <span>
                    {errors[field.name] && errors[field.name].message}
                  </span>
                </FormControl.Feedback>
              }
              type={'number'}
            />
          </FormGroup>
        );
      case 'email':
        return (
          <div>
            {/* TODO: migrate custom Orange MSISDN text to translation strings */}
            <span className='tw-text-black'>
              Votre numéro MSISDN Orange sera utilisé pour un reçu.
            </span>
            <FormGroup className='tw-hidden'>
              <Input
                className={'mb-3'}
                key={`${field.name}`}
                {...register(`${field.name}`, {
                  pattern: {
                    value: new RegExp(field.validation_regex ?? ''),
                    message: `Please enter a valid ${getFieldName(
                      field.name,
                      t,
                      field.label
                    )}`,
                  },

                  required: t<string>('item-redemption-form.field.isRequired', {
                    fieldName: getFieldName(field.name, t, field.label),
                  }),
                })}
                label={
                  <span className={`${styles.textBlack}`}>
                    {getFieldName(field.name, t, field.label)}
                  </span>
                }
                isInvalid={Boolean(errors[field.name])}
                defaultValue={field.data && field.data[0].value}
                feedback={
                  <FormControl.Feedback type={'invalid'}>
                    <span>
                      {errors[field.name] && errors[field.name].message}
                    </span>
                  </FormControl.Feedback>
                }
                type={'email'}
              />
            </FormGroup>
          </div>
        );
      case 'string':
        return (
          <FormGroup>
            <Input
              className={'mb-3'}
              key={`${field.name}`}
              {...register(`${field.name}`, {
                pattern: {
                  value: new RegExp(field.validation_regex ?? ''),
                  message: `Please enter a valid ${getFieldName(
                    field.name,
                    t,
                    field.label
                  )}`,
                },
                required: t<string>('item-redemption-form.field.isRequired', {
                  fieldName: getFieldName(field.name, t, field.label),
                }),
              })}
              label={
                <span className={`${styles.textBlack}`}>
                  {getFieldName(field.name, t, field.label)}
                </span>
              }
              isInvalid={Boolean(errors[field.name])}
              feedback={
                <FormControl.Feedback type={'invalid'}>
                  <span>
                    {errors[field.name] && errors[field.name].message}
                  </span>
                </FormControl.Feedback>
              }
              type={'text'}
            />
          </FormGroup>
        );
    }
  };

  const prepareAndGetInputObject = (): { [key: string]: string | number } =>
    props.fields?.reduce((result: any, field: IField) => {
      result[field.name] = getValues(`${field.name}`);
      return result;
    }, {});

  const redeemItem = () => {
    props.onConfirm(prepareAndGetInputObject());
  };

  const onSubmitForm = () => {
    redeemItem();
  };

  return (
    <Form className={'tw-w-full'} onSubmit={handleSubmit(onSubmitForm)}>
      <div className='tw-SelectPaymentMethod tw-w-full tw-text-black dark:tw-text-zinc-100 tw-text-2xl tw-font-bold tw-leading-7'>
        {t('confirm-details')}
      </div>

      {props.fields && (
        <section className={'pb-3'}>
          <p className={'text-dark'}>
            {props.productType === ProductType.Voucher
              ? t('item-redemption-form.heading.voucher')
              : t('item-redemption-form.heading.top-up')}
          </p>
          {props.fields
            .sort((a, b) => {
              if (a.type === 'email' && b.type !== 'email') {
                return -1;
              } else if (b.type === 'email' && a.type !== 'email') {
                return 1;
              }
              if (a.sort_order && b.sort_order) {
                return a.sort_order - b.sort_order;
              }
              return a.name.localeCompare(b.name);
            })
            .map(renderInputByType)}
        </section>
      )}

      <div className='tw-flex tw-flex-row tw-gap-5'>
        <Button
          variant='secondary'
          type='button'
          loading={props.loading}
          disabled={props.disableButton || props.loading || false}
          onClick={() => {
            props.onBack();
          }}
          semantic={'store-item-modal-confirm'}
          className='tw-w-full lg:tw-p-8  lg:tw-rounded-2xl tw-justify-center tw-items-center tw-gap-4 tw-inline-flex tw-max-h-10'
        >
          Back
        </Button>
        <Button
          variant='primary'
          type='submit'
          loading={props.loading}
          disabled={props.disableButton || props.loading || false}
          semantic={'store-item-modal-confirm'}
          className='tw-w-full lg:tw-p-8 lg:tw-rounded-2xl tw-justify-center tw-items-center tw-gap-4 tw-inline-flex tw-max-h-10'
        >
          Confirm
        </Button>
      </div>
    </Form>
  );
};

export default StoreItemRedemptionDetails;
